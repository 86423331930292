import classes from './LabeledCard.module.css';

import type { BoxProps } from '@mantine/core';
import { Box, Group, Text } from '@mantine/core';
import cx from 'clsx';
import type { ReactNode } from 'react';

import { Link } from '~/features/links';

type LabeledCardStylesNames = 'root' | 'header' | 'label' | 'label-anchor' | 'body';

export interface LabeledCardProps extends BoxProps {
  anchor?: string;
  icon?: ReactNode;
  label?: string;
  labelComponent?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'div';
  actions?: ReactNode;
  children?: ReactNode;
  bodyProps?: BoxProps;
  classNames?: Partial<Record<LabeledCardStylesNames, string>>;
}

export function LabeledCard({
  anchor,
  icon,
  label,
  labelComponent = 'h2',
  actions,
  children,
  className,
  classNames,
  bodyProps,
  ...others
}: LabeledCardProps) {
  const labelContent =
  <Group wrap="nowrap">
      {icon}
      {label}
    </Group>;


  return (
    <Box
      id={anchor}
      className={cx(classes.root, { [classes['scroll-margin-top'] ?? '']: !!anchor }, classNames?.root, className)}
      {...others}>

      <Group justify="space-between" className={cx(classes.header, classNames?.header)}>
        <Text
          component={labelComponent}
          tt="uppercase"
          fw={500}
          size="sm"
          className={cx(classes.label, classNames?.label)}>

          {anchor ?
          <Link to={{ hash: anchor }} className={cx(classes['label-anchor'], classNames?.['label-anchor'])}>
              {labelContent}
            </Link> :

          labelContent}

        </Text>
        {actions}
      </Group>
      <Box className={classNames?.body} {...bodyProps}>
        {children}
      </Box>
    </Box>);

}